import styles from './Title.module.scss'
import TitleDecoration from '@/components/cms/TitleDecoration'

interface TitleProps {
  title: string
  subtitle?: string
  withDecoration?: boolean
  mb?: number | string
  className?: string
  overlap?: boolean
}

export const TitleH2 = ({ title, subtitle, withDecoration = false, mb = 'default', className = '', overlap = false }: TitleProps) => {
  const subTitleSpacing = subtitle !== undefined ? 'me-3' : ''

  return (
    <div className={`mb-${mb} ${className} ${styles.root} ${withDecoration ? styles.withDecoration : ''}`}>
      {withDecoration && <TitleDecoration />}
      {overlap === true ? (
        <>
          <h2 className={`mb-0 ${styles.visible} ${subTitleSpacing}`}>{title}</h2>
          <h2 className={`mb-0 ${styles.positioner} ${subTitleSpacing}`}>{title}</h2>
        </>
      ) : (
        <>
          <h2 className={`mb-0 ${subTitleSpacing}`}>{title}</h2>
        </>
      )}
      {subtitle && <div className={styles.subTitle}>{subtitle}</div>}
    </div>
  )
}
