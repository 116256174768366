import styles from '@/components/models/media/GallerySlider/GallerySlider.module.scss'
import imageGalleryObject from '@/entities/imageGallery/interface'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import SliderWithDots from '@/components/elements/sliders/SliderWithDots'
import GalleryLightBox from '@/components/models/media/GalleryLightBox'
import React, { useState } from 'react'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { IconButton } from '@mui/material'

interface Props {
  gallery: imageGalleryObject
  sliderDotsPosition?: 'bottom' | 'below'
  trackingStringPrefix: string
}
const GallerySlider = ({ gallery, sliderDotsPosition, trackingStringPrefix }: Props) => {
  const sliderOptions = { loop: true, skipSnaps: true, containScroll: 'trimSnaps' }
  const [lightboxOpen, setLightboxOpen] = useState<boolean>(false)
  const [lightboxCurrentSlide, setLightboxCurrentSlide] = useState<number>()

  const handleLightboxDoubleClick = (event: MouseEvent, index: number) => {
    if (event.detail === 2) {
      setLightboxCurrentSlide(index)
      setLightboxOpen(!lightboxOpen)
    }
  }
  const handleLightboxSingleClick = (event: MouseEvent, index: number) => {
    if (event.detail === 1) {
      setLightboxCurrentSlide(index)
      setLightboxOpen(!lightboxOpen)
    }
  }

  const getSlides = (images: Array<any>) => {
    let slides: Array<any> = []
    images.map((image: any, index: number) => {
      const slide = (
        <div className={styles.slideWrap} key={index} data-tr-view={`${trackingStringPrefix}_image:view`}>
          <div className={styles.slideInner} onClick={(event) => handleLightboxDoubleClick(event as unknown as MouseEvent, index)}>
            <CloudinaryImage
              image={image}
              height={360}
              responsive={false}
              lazyload={true}
              sizeAttributes={true}
              trackingStringClick={`${trackingStringPrefix}_image_preview:click_wrong`}
              trackingStringDblClick={`${trackingStringPrefix}_image_preview:double_click`}
            />
            <IconButton
              className={styles.fullScreenButton}
              onClick={(event) => handleLightboxSingleClick(event as unknown as MouseEvent, index)}
              data-tr-click={`${trackingStringPrefix}_fullscreen_icon:click`}
            >
              <FullscreenIcon />
            </IconButton>
          </div>
        </div>
      )
      slides.push(slide)
    })
    return slides
  }

  return (
    <>
      {gallery.images.length > 0 && (
        <>
          <SliderWithDots
            slides={getSlides(gallery.images)}
            options={sliderOptions}
            dotsPosition={sliderDotsPosition}
            autoplay={true}
            arrows
            trackingStringPrefix={trackingStringPrefix}
          />
          <GalleryLightBox images={gallery.images} open={lightboxOpen} currentSlideIndex={lightboxCurrentSlide} />
        </>
      )}
    </>
  )
}

export default GallerySlider
