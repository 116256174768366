import styles from '@/components/models/travelGuide/TravelGuideTeaser/TravelGuideTeaser.module.scss'
import { Card } from 'react-bootstrap'
import travelGuideObject from '@/entities/travelGuide/interface'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'

interface Props {
  travelGuide: travelGuideObject
}

const TravelGuideTeaser = ({ travelGuide }: Props) => {
  return (
    <Card className={`${styles.root} h-100`}>
      <CloudinaryImage
        image={travelGuide.image}
        altDefault={`${travelGuide.firstName} ${travelGuide.lastName}`}
        aspectRatio={1.777}
        width={635}
        responsive={false}
        className={'card-img'}
      />
      <Card.Body className={styles.cardBody}>
        <Card.Title className={styles.cardTitle}>
          {travelGuide.firstName} {travelGuide.lastName}
        </Card.Title>
        <Card.Text dangerouslySetInnerHTML={{ __html: travelGuide.description }} />
      </Card.Body>
    </Card>
  )
}

export default TravelGuideTeaser
