import { getCloudinaryThemeFileUrl } from '@/integrations/cloudinary/helpers'
import { TitleDecorationProps } from 'components/cms/TitleDecoration/TitleDecoration'

const TitleDecoration = ({ className = '' }: TitleDecorationProps) => {
  return (
    <>
      <div className={`root ${className}`} />
      <style jsx>
        {`
          .root {
            background-image: url(${getCloudinaryThemeFileUrl('images/elements/lines-short.png')});
            height: 55px;
            background-repeat: no-repeat;

            background-size: 80% 60px;
            background-position: right;
            position: absolute;
            width: 100%;
            // z-index: -1;
            top: 14px;
            right: -8px;
            @media (min-width: 768px) {
              background-image: url(${getCloudinaryThemeFileUrl('images/elements/lines.png')});
              background-size: 60% 60px;
            }
          }
        `}
      </style>
    </>
  )
}

export default TitleDecoration
