import React, { useState, useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import styles from './EmblaCarousel.module.scss'
import { useInViewport } from 'react-in-viewport'
import DotButton from '@/components/elements/sliders/common/DotButton'
import PrevButton from '@/components/elements/sliders/common/PrevButton'
import NextButton from '@/components/elements/sliders/common/NextButton'

interface Props {
  slides: any[]
  options?: any
  arrows?: boolean
  dotsPosition?: 'bottom' | 'below'
  autoplay?: boolean | number
  ssr?: boolean
  trackingStringPrefix: string
}

const SliderWithDots = ({
  slides,
  options = {},
  arrows = false,
  dotsPosition = 'below',
  autoplay = false,
  ssr = false,
  trackingStringPrefix,
}: Props) => {
  const defaultOptions = { loop: true, skipSnaps: true }
  options = { ...defaultOptions, ...options }
  const dotTheme = dotsPosition === 'below' ? 'dark' : 'light'
  const [domLoaded, setDomLoaded] = useState(ssr)

  //autoplay
  let plugins = []
  if (autoplay) {
    const autoplayRoot = (emblaRoot: any) => emblaRoot.parentElement // Root node
    const autoplayPlugin = Autoplay({ delay: typeof autoplay === 'number' ? autoplay : 5000, rootNode: autoplayRoot })
    plugins.push(autoplayPlugin)
  }

  const [viewportRef, embla] = useEmblaCarousel(options, plugins)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)
  const scrollTo = useCallback((index: number) => embla && embla.scrollTo(index), [embla])
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnDisabled(!embla.canScrollPrev())
    setNextBtnDisabled(!embla.canScrollNext())
  }, [embla, setSelectedIndex])

  // rerender for correct dots (lazy loaded images)
  const myRef = React.createRef<HTMLDivElement>()
  const { inViewport } = useInViewport(myRef, options, { disconnectOnLeave: false })
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!embla) return
      if (inViewport) {
        embla.reInit()
        setScrollSnaps(embla.scrollSnapList())
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [embla, inViewport])

  useEffect(() => {
    setDomLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, setScrollSnaps, onSelect])

  return (
    <>
      {domLoaded && (
        <>
          <div className={styles.embla} ref={myRef} data-tr-view={`${trackingStringPrefix}:view`}>
            <div className={styles.embla__viewport} ref={viewportRef}>
              <div className={styles.embla__container}>
                {slides.map((slide: any, index: number) => (
                  <React.Fragment key={index}>{slide}</React.Fragment>
                ))}
              </div>
            </div>
            {arrows && (
              <div className={styles.embla__buttons}>
                <PrevButton onClick={scrollPrev} disabled={prevBtnDisabled} trackingString={`${trackingStringPrefix}_arrow_nav_prev:click`} />
                <NextButton onClick={scrollNext} disabled={nextBtnDisabled} trackingString={`${trackingStringPrefix}_arrow_nav_next:click`} />
              </div>
            )}
          </div>
          <div className={styles.embla__dots + ' ' + styles[dotsPosition]}>
            {scrollSnaps.map((slide: any, index: number) => (
              <DotButton
                key={index}
                selected={index === selectedIndex}
                theme={dotTheme}
                onClick={() => scrollTo(index)}
                trackingString={`${trackingStringPrefix}_dot_nav:click`}
              />
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default SliderWithDots
