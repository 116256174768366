import styles from './CloudinaryImage.module.scss'
import mediaFileObject from '@/entities/mediaFile/interface'
import cloudinaryUrlGenerator from '@/integrations/cloudinary/cloudinaryUrlGenerator'
import { AdvancedImage, lazyload, placeholder, responsive } from '@cloudinary/react'
import { Plugins } from '@cloudinary/html'
import { fill, scale } from '@cloudinary/url-gen/actions/resize'
import { Cloudinary } from '@cloudinary/url-gen'
import { useDevicePixelRatio } from 'use-device-pixel-ratio'
import { dpr } from '@cloudinary/url-gen/actions/delivery'
import { useContext } from 'react'
import { PrintConfigContext } from '@/app/providers/PrintConfigProvider'

export interface CloudinaryImageProps {
  image: mediaFileObject
  lazyload?: boolean | 'placeholder'
  responsive?: boolean | number[]
  placeholder?: boolean
  width?: number
  height?: number
  sizeAttributes?: boolean
  dpr?: boolean | number
  cropType?: 'fill' | 'scale'
  aspectRatio?: number
  titleDefault?: string
  altDefault?: string
  className?: string
  style?: any
  placeholderClassName?: string
  cld?: Cloudinary
  trackingStringClick?: string
  trackingStringDblClick?: string
}
const CloudinaryImage = ({
  image,
  lazyload: lazyloadOption = true,
  responsive: responsiveOption = true,
  placeholder: placeholderOption = false,
  width,
  height,
  sizeAttributes = false,
  dpr: dprOption = true,
  cropType = 'fill',
  aspectRatio,
  titleDefault,
  altDefault,
  className = '',
  style,
  placeholderClassName = '',
  cld,
  trackingStringClick,
  trackingStringDblClick,
}: CloudinaryImageProps) => {
  const deviceDpr = useDevicePixelRatio()
  const printConfig = useContext(PrintConfigContext)

  lazyloadOption = printConfig.images.lazyloadEnabled === false ? false : lazyloadOption

  if (!image?.publicId) {
    return <div className={`${styles.placeholder} ${placeholderClassName}`} />
  }
  if (!cld) {
    cld = cloudinaryUrlGenerator
  }
  const myImage = cld.image(image.publicId)

  let options: { [x: string]: any } = {}
  if (aspectRatio) {
    options.aspect_ratio = aspectRatio
  }

  myImage.format('auto').quality('auto')

  if (dprOption) {
    const imageDpr = Number.isInteger(dprOption) ? dprOption : deviceDpr
    myImage.delivery(dpr(imageDpr + '.0'))
  }

  let cropSettings
  switch (cropType) {
    case 'fill':
      cropSettings = fill()
      break
    case 'scale':
      cropSettings = scale()
      break
  }
  if (!responsiveOption) {
    if (width) {
      cropSettings.width(width)
    }
    if (height) {
      cropSettings.height(height)
    }
  }
  if (aspectRatio) {
    cropSettings.aspectRatio(aspectRatio)
  }
  if (cropType === 'fill' && (aspectRatio || width || height)) {
    // @ts-ignore
    cropSettings.gravity('auto')
  }
  myImage.resize(cropSettings)

  // see: https://cloudinary.com/documentation/react_image_transformations#plugins
  let plugins: Plugins = []

  // placeholder
  // Note: Not using recommended plugin order, as this loads the display image before placeholder image | see: https://cloudinary.com/documentation/react_image_transformations#plugin_order
  if (placeholderOption || lazyloadOption === 'placeholder') {
    plugins.push(placeholder({ mode: 'blur' })) // see: https://cloudinary.com/documentation/react_image_transformations#image_placeholders
  }

  // lazyload
  if (lazyloadOption) {
    plugins.push(lazyload())
  }

  // responsive breakpoints
  if (responsiveOption) {
    plugins.push(responsive({ steps: 200 }))
  }

  const getSizeAttributes = () => {
    if (sizeAttributes) {
      // custom sizes
      if (width && height) {
        return { width: width, height: height }
      }

      // dynamic sizes by aspect ratio
      if (aspectRatio) {
        if (width) {
          return { width: width, height: width / aspectRatio }
        }
        if (height) {
          return { width: height * aspectRatio, height: height }
        }
      }

      // intrinsic sizes
      return { width: image.width, height: image.height }
    }
    return {}
  }

  const getTrackingAttributes = () => {
    let trackingAttributes: Record<string, string> = {}
    // if "trackingStringClick" is set, build "data-tr-click={trackingStringClick}"
    if (trackingStringClick) {
      trackingAttributes['data-tr-click'] = trackingStringClick
    }

    // if "trackingStringDblClick" is set, build "data-tr-click={trackingStringDblClick}"
    if (trackingStringDblClick) {
      trackingAttributes['data-tr-dblclick'] = trackingStringDblClick
    }
    return trackingAttributes
  }

  return (
    <AdvancedImage
      className={className}
      cldImg={myImage}
      plugins={plugins}
      title={image.title ? image.title : titleDefault ? titleDefault : ''}
      alt={image.description ? image.description : altDefault ? altDefault : ''}
      loading={lazyloadOption === true ? 'lazy' : 'auto'}
      style={style ? style : {}}
      {...getSizeAttributes()}
      {...getTrackingAttributes()}
    />
  )
}

export default CloudinaryImage
